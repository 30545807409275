<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }} Table</h4>
              <div class="card-header-form">
                <div class="input-group">
                  <a
                    class="btn btn-primary"
                    data-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="true"
                    aria-controls="collapseExample"
                    >Filter</a
                  >
                  <router-link
                    class="btn btn-primary mr-1"
                    :to="{ name: 'MasterProductCreate' }"
                    >Create</router-link
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    v-model="search"
                    label="Search"
                    @input="getData"
                  />
                  <div class="input-group-btn">
                    <button class="btn btn-primary">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="collapse show" id="collapseExample">
                <div class="card card-body">
                  <p class="font-weight-bold">Filter</p>
                  <filter-table @reset="resetFilter">
                    <template v-slot:selects>
                      <select-input
                        :value.sync="filter.category_id"
                        @update:value="getData"
                        classes
                        label="Category"
                        :options="data.categories"
                      ></select-input>
                      <select-input
                        :value.sync="filter.type_id"
                        @update:value="getData"
                        classes
                        label="Type"
                        :options="data.types"
                      ></select-input>
                    </template>
                  </filter-table>
                </div>
              </div>
              <v-data-table
                :headers="headers"
                :items="master_product"
                item-key="id"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:item="row">
                  <tr>
                    <td>
                      {{ row.item.product_code }}
                    </td>
                    <td>
                      {{ row.item.name }}
                    </td>
                    <td>
                      {{ row.item.category && row.item.category.name }}
                    </td>
                    <td>
                      {{ row.item.type && row.item.type.name }}
                    </td>
                    <td>
                      <router-link
                        :to="{
                          name: 'MasterProductEdit',
                          params: { id: row.item.id },
                        }"
                        class="badge badge-warning mr-1"
                        >Edit</router-link
                      >
                      <button
                        @click="deleteMasterProduct(row.item.id)"
                        class="badge badge-danger"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div class="card-footer text-right">
              <div class="float-right">
                <nav>
                  <ul class="pagination" style="overflow: auto">
                    <v-pagination
                      v-model="page"
                      :length="pageCount"
                      :total-visible="7"
                      @input="getData"
                    ></v-pagination>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import {
  MasterProductRepository,
  ProductCategoryRepository,
  ProductTypeRepository,
} from "../../repositories/RepositoryFactory";

export default {
  name: "MasterProduct",
  data() {
    return {
      search: "",
      page: 1,
      pageCount: 0,
      name: "Master Product",
      master_product: [],
      filter: {
        category_id: "",
        type_id: "",
      },
      data: {
        categories: [],
        types: [],
      },
    };
  },
  mounted() {
    ProductCategoryRepository.getForSelect().then(({ data }) => {
      this.data.categories = data.data;
    });
    ProductTypeRepository.getForSelect().then(({ data }) => {
      this.data.types = data.data;
    });

    this.getData();
  },
  methods: {
    getData(page = 1) {
      MasterProductRepository.get({
        page: page,
        category_id: this.filter.category_id,
        type_id: this.filter.type_id,
      }).then((res) => {
        console.log(res.data);
        this.pageCount = res.data.data.last_page;
        this.master_product = res.data.data.data;
      });
    },
    resetFilter() {
      this.search = "";
      Object.entries(this.filter).forEach((e) => {
        this.filter[e[0]] = "";
      });
      this.getData().then(() => {
        this.page = 1;
      });
    },
    deleteMasterProduct(id) {
      swal({
        title: "Are you sure?",
        text:
          "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete(this.$store.state.api + "master_product/" + id, {
              headers: { Authorization: this.$store.state.token },
            })
            .then(
              () =>
                (this.master_product = this.master_product.filter((v) => {
                  return v.id != id;
                }))
            )
            .catch((err) => {
              console.log(err);
              swal("Error!", err.message, "error");
            });
          swal("Poof! Your product type file has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your product type file is safe!");
        }
      });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Product Code",
          value: "product_code",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Category",
          value: "category_id",
        },
        {
          text: "Type",
          value: "type_id",
        },
        { text: "Action" },
      ];
    },
  },
};
</script>
