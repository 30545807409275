import { apiDelete, apiGet } from '../../services/api'

const path = 'menu';

export default {
    get() {
        return apiGet(path)
    },
    delete(id) {
        return apiDelete(`${path}/${id}`)
    }
}