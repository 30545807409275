<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table>
        <h1>{{ name }}</h1>
      </template>
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="masterProductUpdate">
              <div class="card-body">
                <text-input :value.sync="form.name" label="Name"></text-input>
                <text-input v-model="form.product_code" :value.sync="form.product_code" label="Product Code"></text-input>
                <div class="row">
                  <text-input :value.sync="form.price" classes="col-4 form-group" label="Price"></text-input>
                  <text-input :value.sync="form.qty" classes="col-4 form-group" label="Quantity"></text-input>
                  <select-input :value.sync="form.unit_id" classes="col-4 form-group" label="Unit" :options="units"></select-input>
                </div>
                <file-input :value.sync="form.image" label="Image"></file-input>
                <div class="row">
                  <select-input :value.sync="form.type_id" classes="col-6 form-group" label="Product Type" :options="product_type"></select-input>
                  <select-input :value.sync="form.category_id" classes="col-6 form-group" label="Category" :options="product_category"></select-input>
                </div>
              </div>
              <div class="card-footer text-right">
                <button class="btn btn-primary mr-1" type="submit" :disabled="isSubmitting">Submit</button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { MasterProductRepository, ProductCategoryRepository, ProductTypeRepository, UnitRepository, VenueRepository } from '../../repositories/RepositoryFactory';
export default {
  name: "EditMasterProduct",
  data() {
    return {
      name: "Form Master Product",
      form: {
        id: this.$route.params.id,
        name: null,
        product_code: null,
        price: null,
        image: null,
        type_id: null,
        category_id: null,
        qty: 0,
        unit_id: null
      },
      product_type: [],
      venue: [],
      product_category: [],
      units: [],
      errors: {},
      isSubmitting: false,
    };
  },
  beforeMount() {
    VenueRepository.getForSelect().then(({data}) => {
      this.venue = data.data
    })
    ProductTypeRepository.getForSelect().then(({data}) => {
      this.product_type = data.data
    })
    ProductCategoryRepository.getForSelect().then(({data}) => {
      this.product_category = data.data
    })
    UnitRepository.getForSelect().then(({data}) => {
      this.units = data.data 
    })
    MasterProductRepository.getDetail(this.form.id).then(({data}) => {
      Object.keys(this.form).forEach(key => {
        this.form[key] = data.data[Object.keys(data.data).filter(e => e == key)]
      })
      this.form.image = null
      this.$forceUpdate()
    })
  },
  methods: {
    masterProductUpdate() {
      MasterProductRepository.update(this.form).then(({data}) => {
        console.log(data)
      })
    },
  },
};
</script>
