import axios from "axios"
import store from "../../store"

const path = 'order_department'
export default {
    getStatus() {
        return axios.get(store.state.api + path + '/get_status', {
            headers: { Authorization: store.state.token },
        })
    }
}