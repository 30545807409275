import axios from "axios"
import store from "../../store"

const path = 'payment_type'
export default {
    get() {
        return axios.get(store.state.api + path, {
            headers: { Authorization: store.state.token },
        })
    }
}