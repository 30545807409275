import { apiGet, apiPost } from "../../services/api"

const path = 'master_product'
export default {
    get(data) {
        return apiGet(path, data).then(res => {
            res.data.data.data = res.data.data.data.map(e => {
                if(e.category == null) {
                    e.category = {}
                    e.category.name = '-'
                }
                if(e.type == null) {
                    e.type = {}
                    e.type.name = '-'
                }
                return e
            }) 
            return res
        })
    },
    getForSelect() {
        return apiGet(`${path}/all`).then(res => {
            res.data.data = res.data.data.map(e => {
                return {
                    text: `${e.name} - ${e.product_code}`,
                    value: e.id
                  }
            })
            return res
        })
    },
    create(data) {
        let formData = new FormData()
        Object.entries(data).forEach(e => {
            if(e[1] != null) {
                formData.set(e[0], e[1])
            }
        })
        
        return apiPost(`${path}/store`, formData)
    },
    getDetail(id) {
        return apiGet(`${path}/${id}`)
    },
    update(data) {
        let formData = new FormData()
        Object.entries(data).forEach(e => {
            if(e[1] != null) {
                formData.set(e[0], e[1])
            }
        })

        return apiPost(`${path}/update/${data.id}`, formData)
    }
}