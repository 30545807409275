import { apiGet } from "../../services/api"

const path = 'product_category'
export default {
    get() {
        return apiGet(path)
    },
    getForSelect() {
        return apiGet(path).then(res => {
            res.data.data = res.data.data.map(e => {
                return {
                    text: `${e.name}`,
                    value: e.id
                }
            })
            res.data.data.push({
                text: '--Product Category--',
                value: ''
            })
            return res
        })
    }
}