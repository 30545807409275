import { apiGet } from "../../services/api"

const path = 'item'
export default {
    get() {
        return apiGet(path)
    },
    getForSelect() {
        return apiGet(path).then(res => {
            res.data.data = res.data.data.map(e => {
                return {
                    value: e.id,
                    text: e.name
                }
            })

            return res
        })
    }
}