import MasterProduct from './MasterProduct.vue'
import MasterProductCreate from './Create.vue'
import MasterProductEdit from './Edit.vue'

export default [
    {
        path: '/master_product',
        name: 'MasterProduct',
        component: MasterProduct,
        meta: {
            guest: false,
            authenticated: true
        }
    },
    {
        path: '/master_product/create',
        name: 'MasterProductCreate',
        component: MasterProductCreate,
        meta: {
            guest: false,
            authenticated: true
        }
    },
    {
        path: '/master_product/edit/:id',
        name: 'MasterProductEdit',
        component: MasterProductEdit,
        meta: {
            guest: false,
            authenticated: true
        }
    },
]
