import { apiGet } from "../../services/api"

const path = 'product'
export default {
    get(data) {
        return apiGet(path, data).then(res => {
            console.log(res.data)
            res.data.data.data = res.data.data.data.map((e, i) => {
                if(e.master == null) {
                    e.master = {}
                    e.master.name = '-'
                }
                if(e.venue == null) {
                    e.venue = {}
                    e.venue.name = ''
                }

                return e
            })
            return res
        })
    },
    getAll(data) {
        return apiGet(`${path}/all`, data)
    },
    getMinimal(selectable = false) {
        return apiGet(`${path}/all/minimal`).then(res => {
            if(selectable) {
                res.data.data = res.data.data.map(e => {
                    return {
                        value: e.id,
                        text: e.name
                    }
                })
            }
            return res
        })
    }
}