import { apiGet, apiDelete, apiPost } from "../../services/api";

const path = 'sub_item';
export default {
    get() {
        return apiGet(path)
    },
    delete(id) {
        return apiDelete(`${path}/${id}`)
    },
    create(data) {
        return apiPost(`${path}/store`, data)
    },
    show(id) {
        return apiGet(`${path}/${id}`)
    }
}