import { apiDelete, apiGet, apiPost } from "../../services/api"

const path = 'order'
export default {
    create(data) {
        return apiPost(`${path}/store`, data)
    },
    get() {
        return apiGet(path)
    },
    delete(id) {
        return apiDelete(`${path}/${id}`)
    }
}