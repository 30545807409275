import { apiGet, apiPost } from "../../services/api"

const path = 'crud_service'

export default {
    get(filter) {
        return apiGet(path, filter)
    },
    delete(id) {
        return apiPost(`${path}/delete/${id}`)
    },
    create(data) {
        return apiPost(`${path}/store`, data)
    },
    update(data) {
        return apiPost(`${path}/${data.id}`, data)
    }
}